<template>
  <div class="add-store-container" v-loading="boxLoading">
    <div class="top-info">
      <span>供应商ID ： {{ data.storeId }}</span>
      <span>供应商名称 ：{{ data.storeName }}</span>
      <span>供应商类型 ： {{ data.storeTypeName }}</span>
      <span>供应商性质 ： {{ data.storeAttrName }}</span>
      <span>入网状态 ： {{ data.enterNetworkStatus == 'NOT_APPLICATION' ? '待提交申请' : data.enterNetworkStatusName }} ​</span>
    </div>
    <el-form class="form-container" ref="form" :model="form" :rules="rules" label-width="160px">
      <el-row :gutter="24">
        <el-col :span="12" style="border-right: 1px solid #eee">
          <div class="title">
            <span class="title-mark"></span>
            <span class="title-text">主体信息</span>
          </div>
          <el-form-item label="主体名称:" prop="subjectName">
            <el-input :disabled="disabled" v-model="form.subjectName" placeholder="与证件主体名称一致"></el-input>
          </el-form-item>
          <el-form-item label="主体简称:" prop="shortName">
            <el-input :disabled="disabled" v-model="form.shortName" placeholder="请输入主体简称"></el-input>
          </el-form-item>

          <div class="title">
            <span class="title-mark"></span>
            <span class="title-text">法人信息</span>
          </div>
          <el-form-item label="法人姓名:" prop="payeeName">
            <el-input :disabled="disabled" v-model="form.payeeName" placeholder="请输入收款人姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="法人手机号:" prop="payeePhone">
            <el-input :disabled="disabled" v-model="form.payeePhone" placeholder="请输入法人手机号" clearable></el-input>
          </el-form-item>
          <el-form-item label="法人证件类型:" prop="payeeCardType">
            <el-select :disabled="disabled" v-model="form.payeeCardType" placeholder="请选择经营者/法人证件类型">
              <el-option label="身份证" :value="1"></el-option>
              <el-option label="护照" :value="2"></el-option>
              <el-option label="港澳居民来往内地通行证" :value="3"></el-option>
              <el-option label="台胞证" :value="4"></el-option>
              <el-option label="士兵证" :value="5"></el-option>
              <el-option label="军官证" :value="6"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="法人证件编号:" prop="payeeCardNo">
            <el-input :disabled="disabled" v-model="form.payeeCardNo" placeholder="与经营者/法人身份证一致" clearable></el-input>
          </el-form-item>
          <el-form-item label="法人身份证正面照片:" prop="payeeCardFrontImg">
            <uploadImg :disabled="disabled" :imageUrl.sync="form.payeeCardFrontImg" text="人像面的证件照片" />
          </el-form-item>
          <el-form-item label="法人身份证反面照片:" prop="payeeCardBackImg">
            <uploadImg :disabled="disabled" :imageUrl.sync="form.payeeCardBackImg" text="国徽面的证件照片" />
            <div style="color: #999">如为身份证，请上传国徽面照片；其余法人证件（如港澳台通行证）请上传人像面反面照片；如该类型法人证件无反面（护照），再次上传正面照片即可。</div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="title">
            <span class="title-mark"></span>
            <span class="title-text">经营地址信息</span>
          </div>
          <el-form-item label="实际经营详细地址:" prop="address">
            <el-input :disabled="disabled" v-model="form.address" placeholder="请输入详细地址及门牌号"></el-input>
          </el-form-item>
          <div class="title" style="margin-top: 35px">
            <span class="title-mark"></span>
            <span class="title-text">结算账户信息</span>
          </div>
          <el-form-item label="银行账户类型:" prop="bankType">
            <el-select :disabled="disabled" v-model="form.bankType" placeholder="请选择银行账户类型">
              <el-option label="借记卡" :value="1"></el-option>
              <el-option label="存折" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="银行账户号码:" prop="cardNumber">
            <el-input :disabled="disabled" v-model="form.cardNumber" placeholder="请输入提现银行账户号码"></el-input>
          </el-form-item>
          <el-form-item label="银行账户开户总行:" prop="bankId">
            <el-select :disabled="disabled" v-model="form.bankId" placeholder="请选择银行账户开户总行">
              <el-option v-for="(item, i) in backList" :key="i" :label="item.dictionaryName" :value="item.dictionaryValue"></el-option>
            </el-select>
          </el-form-item>
          <div v-if="data.enterNetworkStatus && data.enterNetworkStatus != 'NOT_APPLICATION'">
            <div class="title" style="margin-top: 35px">
              <span class="title-mark"></span>
              <span class="title-text">入网结果通知</span>
            </div>
            <el-form-item label="进度说明:">
              <el-input disabled v-model="form.progressDesc"></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-form-item class="form-submit-container">
        <el-button v-if="!disabled" class="form-submit-btn" type="primary" :loading="submitLoadingStatus" @click="submitForm()">提交</el-button>
        <el-button class="form-submit-btn" @click="handleComeBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDictionaryList } from '@/api/common';
import { storeApply, getStoreApplyInfo, getCitys } from '@/api/store';
import uploadImg from '@/components/uploadImg';
export default {
  components: {
    uploadImg,
  },
  data() {
    return {
      boxLoading: false,
      submitLoadingStatus: false,
      form: {
        provinceId: '',
        cityId: '',
        districtId: '',
      },
      data: {},
      disabled: true,
      rules: {
        subjectName: [{ required: true, message: '请输入主体名称', trigger: 'change' }],
        shortName: [{ required: true, message: '请输入主体简称', trigger: 'change' }],
        payeeName: [{ required: true, message: '请输入法人姓名', trigger: 'blur' }],
        payeePhone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入法人手机号'));
              } else if (!/^1[3-9]\d{9}$/.test(value)) {
                callback(new Error('手机号格式不正确'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        payeeCardType: [{ required: true, message: '请选法人证件类型', trigger: 'change' }],
        payeeCardNo: [{ required: true, message: '请输入法人证件编号', trigger: 'blur' }],
        payeeCardBackImg: [{ required: true, message: '请选择法人身份证反面照片', trigger: 'change' }],
        payeeCardFrontImg: [{ required: true, message: '请选择法人身份证正面照片', trigger: 'change' }],
        address: [{ required: true, message: '请输入实际经营详细地址', trigger: 'change' }],
        bankType: [{ required: true, message: '请选择银行账户类型', trigger: 'blur' }],
        cardNumber: [{ required: true, message: '请输入提现银行账户号码', trigger: 'blur' }],
        bankId: [{ required: true, message: '请选择银行账户开户总行', trigger: 'blur' }],
      },
      action: process.env.VUE_APP_BASE_API,
      backList: [],
    };
  },
  created() {
    this.data = this.$route.query;
    if (this.data.enterNetworkStatus == '' || this.data.enterNetworkStatus == 'REVIEW_BACK' || this.data.enterNetworkStatus == 'NOT_APPLICATION') {
      this.disabled = false;
    }
    this.getData();
    this.getStoreApplyInfo();
  },
  methods: {
    getStoreApplyInfo() {
      getStoreApplyInfo({ data: this.$route.query.storeId }).then((res) => {
        if (res.data.id) {
          this.form = { ...res.data };
          this.form.bankId = this.form.bankId + '';
        }
        if (this.form.progressDescription && this.data.enterNetworkStatus == 'AUTHENTICITY_VERIFYING') {
          let arr = JSON.parse(this.form.progressDescription);
          if (arr.length > 1) {
            this.form.progressDesc = '待短信邮箱验证';
          } else {
            this.form.progressDesc = this.getProgressDescriptionName(arr[0]);
          }
        }
        if (this.data.enterNetworkStatus == 'AGREEMENT_SIGNING') {
          this.form.progressDesc = this.form.agreementSignUrl;
        }
      });
    },
    async getData() {
      try {
        this.boxLoading = true;
        this.backList = (await getDictionaryList({ data: { key: 'store_tx_bank' } })).data.childs || [];
        this.boxLoading = false;
      } catch (error) {
        this.boxLoading = false;
      }
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoadingStatus = true;
          let data = {};
          data.address = this.form.address;
          data.payeeCardBackImg = this.form.payeeCardBackImg;
          data.payeeCardFrontImg = this.form.payeeCardFrontImg;
          data.payeeCardNo = this.form.payeeCardNo;
          data.payeeCardType = this.form.payeeCardType;
          data.payeeName = this.form.payeeName;
          data.payeePhone = this.form.payeePhone;
          data.subjectName = this.form.subjectName;
          data.shortName = this.form.shortName;
          data.bankType = this.form.bankType;
          data.cardNumber = this.form.cardNumber;
          data.bankId = this.form.bankId;

          data.storeAttr = '2';
          data.storeId = this.data.storeId;
          storeApply({ data: data })
            .then((res) => {
              this.$notify.success({ title: '成功', message: '操作成功', duration: 2000 });
              this.$router.back();
              this.submitLoadingStatus = false;
            })
            .catch((err) => {
              this.submitLoadingStatus = false;
            });
        }
      });
    },
    handleComeBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <el-upload :disabled="disabled" class="my-uploader" :action="action" :show-file-list="false" :on-error="upError" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
    <div v-loading="loading">
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <div v-else class="text">
        <div>点击上传</div>
        <div>{{ text }}</div>
      </div>
    </div>
  </el-upload>
</template>

<script>
export default {
  props: ['text', 'imageUrl', 'size', 'disabled'],
  data() {
    return {
      action: process.env.VUE_APP_BASE_API + 'misc-web-api/common/file/uploadImageYeepay',
      loading: false,
    };
  },
  methods: {
    upError() {
      this.loading = false;
    },
    handleAvatarSuccess(res, file) {
      this.loading = false;
      let imageUrl = res.data.http;
      this.$emit('update:imageUrl', imageUrl);
    },
    beforeAvatarUpload(file) {
      let isLt2M = false;
      if (this.size) {
        isLt2M = file.size / 1024 / 1024 < 2.5;
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2.5MB!');
        } else {
          this.loading = true;
        }
      } else {
        isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!');
        } else {
          this.loading = true;
        }
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="less" >
.my-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    background-color: #f5f5f5;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .text {
    color: #8c939d;
    width: 120px;
    height: 120px;
    font-size: 13px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
}
</style>